export type undefined = nul;
export const undefined: nul = void 0;

export function fallback<E>(value: E | nul, def: E): E {
	if (value == null)
		return def;
	return value;
}

export function keys(obj: object) {
	return Object.keys(obj);
}

export function get(obj: object, key: string) {
	const desc = Object.getOwnPropertyDescriptor(obj, key);
	if (desc == null)
		return null;

	const get = desc.get;
	if (get != null)
		return get();

	return desc.value;
}

export function set(obj: object, key: string, value?: any) {
	const set = Object.getOwnPropertyDescriptor(obj, key)?.set;
	if (set != null)
		set(value);
	else define(obj, key, value);
}

export function has(obj: object, key: string) {
	return Object.getOwnPropertyDescriptor(obj, key) != null;
}

type getter = () => any;
type setter = (v: any) => void;
interface PropertyAttributeInit {
	readonly writable?: bool;
	readonly configurable?: bool;
	readonly enumerable?: bool;
	readonly get?: getter;
	readonly set?: setter;
}

export function remove(obj: object, key: string) {
	delete (obj as any)[key];
}

export function clean<T extends object>(obj: T): T {
	for (let k of keys(obj)) {
		const desc = Object.getOwnPropertyDescriptor(obj, k)!;
		if (desc.value === void 0) {
			remove(obj, k);
		}
	}
	return obj;
}

export function define(obj: object, key: string, value?: any, attr?: PropertyAttributeInit) {
	Object.defineProperty(obj, key, clean({ ...attr, value }));
}

export function defineRo(obj: object, key: string, value?: any) {
	define(obj, key, value, {
		writable: false,
		configurable: false,
		enumerable: false
	});
}

export function defineGet(obj: object, key: string, get?: getter) {
	define(obj, key, void 0, { get });
}

export function defineSet(obj: object, key: string, set?: setter) {
	define(obj, key, void 0, { set });
}

export function assign(to: object, from: object, attr?: PropertyAttributeInit) {
	const init = fallback(attr, {});
	for (let k of keys(from)) {
		const desc = Object.getOwnPropertyDescriptor(from, k)!;

		define(to, k, desc.value, {
			...init,
			get: desc.get == null ? init.get : desc.get,
			set: desc.set == null ? init.set : desc.set
		})
	}
}

export function clone<E extends object>(obj: E): E {
	return Object.create(obj) as E;
}

export function createReadonlyObject<E extends object>(baseObj: E): E {
	let newObj = {};
	assign(newObj, baseObj, {
		writable: false,
		configurable: false,
		enumerable: false
	});

	return newObj as E;
}

export class Null {
	protected readonly __defineGetter__ = void 0;
	protected readonly __defineSetter__ = void 0;
	protected readonly __lookupGetter__ = void 0;
	protected readonly __lookupSetter__ = void 0;
	protected readonly __proto__ = void 0;
	protected readonly isPrototypeOf = void 0;
	protected readonly propertyIsEnumerable = void 0;
	protected readonly toLocaleString = void 0;
	protected readonly toString = void 0;
	protected readonly valueOf = void 0;

	protected constructor() {
		assign(this, this, {
			writable: false,
			configurable: false,
			enumerable: false
		});
		define(this, "constructor", void 0, {
			writable: false,
			configurable: false,
			enumerable: false
		});
	}
}

export class RawObject extends Null {
	constructor(initObj?: object) {
		super();
		if (initObj != null)
			assign(this, initObj, {});
	}
}
