import AlertDialog from "./AlertDialog";

const alert = async (message?: string, title?: string) => {
	const dialog = new AlertDialog({
		message: message,
		title: title,
		positiveButton: {
			text: "OK",
			onclick: () => {
				dialog.dismiss();
			}
		}
	});
	await dialog.show();
};

export default alert;
