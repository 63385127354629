import Dialog from "./Dialog";

export default class WindowDialog extends Dialog implements globalThis.WindowDialog {
	declare readonly title: string | nul | WindowDialogTitleBarInit;
	declare readonly frame: nul | WindowDialogFrameInit;

	constructor(init?: WindowDialogInit) {
		super(init);
	}

	protected async initializeDocument() {
	}
}
