import Dialog from "./Dialog";
import { fallback } from "./util"

function initButton(config: AlertDialogButtonInit | nul, element: HTMLElement) {
	if (config != null) {
		element.innerHTML = fallback(config.text, "");
		if (fallback(config.disabled, false))
			element.setAttribute("disabled", "true");
		else element.onclick = config.onclick as any;
	} else element.remove();
}

export default class AlertDialog extends Dialog implements globalThis.AlertDialog {
	declare readonly title: string | nul;
	declare readonly message: string | nul;
	declare readonly elements: DialogElements | nul;
	declare readonly positiveButton: AlertDialogButtonInit | nul;
	declare readonly negativeButton: AlertDialogButtonInit | nul;
	declare readonly neutralButton: AlertDialogButtonInit | nul;

	constructor(init?: AlertDialogInit) {
		super(init);
	}

	/** @Override */
	protected async initializeDocument() {
		this.dialogElement!.innerHTML = "<div id=\"element-container\"></div><div id=\"button-bar\"><div id=\"negative-button\" class=\"button\"></div><div id=\"neutral-button\" class=\"button\"></div><div id=\"positive-button\" class=\"button\"></div></div>";
		const doc = this.frameDocument!;
		const ec = doc.getElementById("element-container")!;

		const title = this.title;
		if (title != null) {
			const elem = doc.createElement("div");
			elem.className = "title";
			elem.innerHTML = title;
			ec.appendChild(elem);
		}

		const message = this.message;
		if (message != null) {
			const elem = doc.createElement("div");
			elem.className = "message";
			elem.innerHTML = message;
			ec.appendChild(elem);
		}

		const elements = this.elements;
		if (elements != null) {
			for (let element of elements) {
				if (element instanceof HTMLElement) {
					ec.appendChild(element);
				} else {
					const elem = doc.createElement(element.type);
					elem.className = fallback(element.className, "");
					elem.innerHTML = fallback(element.innerHTML, "");
					const style = element.style;
					if (style != null)
						elem.setAttribute("style", style);
					for (let attr of fallback(element.attributes, []))
						elem.setAttribute(attr.name, attr.value);
					ec.appendChild(elem);
				}
			}
		}

		initButton(this.positiveButton, doc.getElementById("positive-button")!);
		initButton(this.negativeButton, doc.getElementById("negative-button")!);
		initButton(this.neutralButton, doc.getElementById("neutral-button")!);
	}
}
