import Dialog from "./Dialog";
import AlertDialog from "./AlertDialog";
import WindowDialog from "./WindowDialog";
import alert from "./alert";
import confirm from "./confirm";
import prompt from "./prompt";
import block from "./block";
import form from "./form";
import { createReadonlyObject, defineRo } from "./util";

const webAlert: WebAlert = createReadonlyObject({
	Dialog,
	AlertDialog,
	WindowDialog,
	alert,
	confirm,
	prompt,
	block,
	form
});

// unchecked global cast
const __global = window as any;
defineRo(__global, "webAlert", webAlert);

// preserve original functions
__global.nativeAlert = __global.alert;
__global.nativeConfirm = __global.confirm;
__global.nativePrompt = __global.prompt;

// force override global functions
__global.alert = webAlert.alert;
__global.confirm = webAlert.confirm;
__global.prompt = webAlert.prompt;
__global.block = webAlert.block;
__global.form = webAlert.form;
