import AlertDialog from "./AlertDialog";

const block = async (message?: string, title?: string) => {
	await new AlertDialog({
		message: message,
		title: title
	}).show();
};

export default block;
