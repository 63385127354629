import AlertDialog from "./AlertDialog";
import { fallback } from "./util";

const prompt = (message?: string, defaultValue?: string, title?: string, placeholder?: string): Promise<string | null> => {
	return new Promise(resolve => {
		const inputElem = document.createElement("input");
		inputElem.type = "text";
		inputElem.value = fallback(defaultValue, "");
		inputElem.placeholder = fallback(placeholder, "");
		inputElem.className = "input";

		const dialog = new AlertDialog({
			message: message,
			title: title,
			positiveButton: {
				text: "OK",
				onclick: () => {
					dialog.dismiss();
					resolve(inputElem.value);
				}
			},
			negativeButton: {
				text: "Cancel",
				onclick: () => {
					dialog.cancel();
					resolve(null);
				}
			},
			elements: [inputElem]
		});

		inputElem.onkeydown = (e) => {
			if (e.keyCode == 13) { // Enter
				e.preventDefault();
				dialog.positiveButton!.onclick!();
			}
		};

		dialog.show();
	});
};

export default prompt;
