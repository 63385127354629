import AlertDialog from "./AlertDialog";
import { fallback } from "./util";

const form = (message?: string, title?: string, _elements?: FormElementInit[]): Promise<FormResults | null> => {
	return new Promise(resolve => {
		const elements = fallback(_elements, []);
		const htmlElements: HTMLElement[] = [];
		for (let element of elements) {
			const label = element.label;
			if (label != null) {
				const labelElem = document.createElement("div");
				labelElem.className = "message";
				labelElem.innerHTML = label;
				htmlElements.push(labelElem);
			}

			let input = element.input;
			if (input != null) {
				if (typeof input == "string")
					input = { type: input };

				const inputElem = document.createElement("input");
				inputElem.className = "input";
				inputElem.type = fallback(input.type, "text");
				inputElem.value = fallback(input.value, "");
				inputElem.placeholder = fallback(input.placeholder, "");
				inputElem.checked = fallback(input.checked, false);
				inputElem.disabled = fallback(input.disabled, false);

				for (let attr of fallback(input.attributes, []))
					inputElem.setAttribute(attr.name, attr.value);

				htmlElements.push(inputElem);
				(element as any)["\ud801"] = inputElem;
			}
		}

		const dialog = new AlertDialog({
			message: message,
			title: title,
			elements: htmlElements,
			positiveButton: {
				text: "OK",
				onclick: () => {
					dialog.dismiss();
					const result: FormResults = [];
					for (let element of elements) {
						const inputElem: HTMLInputElement | undefined = (element as any)["\ud801"];
						const res: any = { label: element.label };
						if (inputElem != null) {
							const attr: ElementAttributeInit[] = [];
							for (let i = 0; i < inputElem.attributes.length; i++) {
								const it = inputElem.attributes.item(i)!;
								attr.push({
									name: it.name,
									value: it.value
								});
							}
							res.value = inputElem.value;
							res.checked = inputElem.checked;
							res.attributes = attr;
						}

						result.push(res);
					}
					resolve(result);
				}
			},
			negativeButton: {
				text: "Cancel",
				onclick: () => {
					dialog.cancel();
					resolve(null);
				}
			}
		});
		dialog.show();
	});
};

export default form;
