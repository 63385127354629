import AlertDialog from "./AlertDialog";

const confirm = (message?: string, title?: string): Promise<boolean> => {
	return new Promise(resolve => {
		const dialog = new AlertDialog({
			message: message,
			title: title,
			positiveButton: {
				text: "OK",
				onclick: () => {
					dialog.dismiss();
					resolve(true);
				}
			},
			negativeButton: {
				text: "Cancel",
				onclick: () => {
					dialog.cancel();
					resolve(false);
				}
			}
		});
		dialog.show();
	});
};

export default confirm;
