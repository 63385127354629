import { fallback, Null, RawObject } from "./util";
import { dialogDoc } from "../inlined";

function waitForBody(): Promise<HTMLElement> {
	return new Promise(resolve => {
		const body = document.body;
		if (body == null) {
			document.addEventListener("DOMContentLoaded", () => {
				resolve(document.body);
			}, { once: true, passive: true });
		} else resolve(body);
	});
}

function createFrame() {
	const frame = document.createElement("iframe");
	frame.setAttribute("type", "text/plain");
	frame.setAttribute("width", "1024");
	frame.setAttribute("height", "768");
	frame.setAttribute("style", "position:absolute;display:block;width:100%;height:100%;top:0px;left:0px;right:0px;bottom:0px;border:none;");
	frame.setAttribute("scrolling", "no");
	frame.setAttribute("loading", "eager");
	frame.setAttribute("allowfullscreen", "true");
	frame.setAttribute("allowtransparency", "true");
	frame.setAttribute("fetchpriority", "high");
	return frame;
}

function waitFor(element: HTMLElement): Promise<void> {
	return new Promise(resolve => {	
		element.addEventListener("load", () => {
			resolve();
		}, { once: true, passive: true });
	});
}

export default class Dialog extends RawObject implements globalThis.Dialog {
	declare readonly width: int | nul;
	declare readonly height: int | nul;
	declare readonly x: int | nul;
	declare readonly y: int | nul;
	declare readonly paddingX: int | nul;
	declare readonly paddingY: int | nul;
	declare readonly centered: bool | nul;
	declare readonly draggable: bool | nul;
	declare readonly resizable: bool | nul;
	readonly canceled: bool = false;

	// related elements
	protected currentFrame: HTMLIFrameElement | nul;
	protected frameWindow: Window | nul;
	protected frameDocument: Document | nul;
	protected dialogElement: HTMLElement | nul;

	constructor(init?: DialogInit) {
		super(init);
	}

	protected async loadDocument() {
		const frame = createFrame();
		const body = await waitForBody();

		frame.setAttribute("srcdoc", dialogDoc);
		body.scrollTo(0, 0);
		body.style.overflow = "hidden";
		body.appendChild(frame);
		await waitFor(frame);

		const win = frame.contentWindow!;
		const doc = win.document;

		this.frameWindow = win;
		this.frameDocument = doc;
		this.currentFrame = frame;
		this.dialogElement = doc.getElementById("dialog");
	}

	protected async initializeDocument() {
	}

	protected async measureDocument() {
		const dialog = this.dialogElement!;
		const frame = this.currentFrame!;

		const width = this.width;
		if (width != null)
			dialog.style.width = width + "px";

		const height = this.height;
		if (height != null)
			dialog.style.height = height + "px";

		const paddingX = this.paddingX;
		if (paddingX != null) {
			dialog.style.paddingLeft = paddingX + "px";
			dialog.style.paddingRight = paddingX + "px";
		}

		const paddingY = this.paddingY;
		if (paddingY != null) {
			dialog.style.paddingTop = paddingY + "px";
			dialog.style.paddingBottom = paddingY + "px";
		}

		if (fallback(this.centered, true)) {
			const resize = async () => {
				const dw = dialog.clientWidth + 2; // includes borders
				const dh = dialog.clientHeight + 2;

				dialog.style.left = Math.floor((frame.clientWidth - dw) / 2) + "px";
				dialog.style.top = Math.floor((frame.clientHeight - dh) / 2) + "px";
			};

			await resize();
			dialog.addEventListener("resize", resize, true);
			window.addEventListener("resize", resize, true);
		} else {
			const x = this.x;
			if (x != null)
				dialog.style.left = x + "px";

			const y = this.y;
			if (y != null)
				dialog.style.top = y + "px";

		}

		if (fallback(this.resizable, false)) {
			dialog.style.resize = "both";
			dialog.style.overflow = "auto";
		}
	}

	async show() {
		await this.loadDocument();
		await this.initializeDocument();
		await this.measureDocument();
	}

	dismiss() {
		if (this.currentFrame != null) {
			// unlock scrolling
			document.body.style.overflow = "";

			this.currentFrame.remove();
			this.currentFrame = null;
		}
	}

	cancel() {
		this.dismiss();
		Object.defineProperty(this, "canceled", {
			value: true,
			writable: false,
			configurable: false,
			enumerable: false
		});
	}

	close() {
		this.dismiss();
	}
}
